import SwitchEditData from './editForm/switch.edit.data';
import SwitchEditDisplay from './editForm/switch.edit.display';
import RadioEditValidation from './editForm/switch.edit.validation';

export default function(...extend) {
  return {
    components: [
      {
          components: [
            {
              label: "Configuration",
              key: 'data',
              components: SwitchEditData
            },
            // {
            //   label: "Display",
            //   key: "display",
            //   components: SwitchEditDisplay
            // },
          ],
          "key": "tabs",
          "type": "tabs",
          "input": false,
          "tableView": false
      },
    ]
  }
}
